import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const Home = lazy(() => import('./components/Authentication/AuthenticationApp'));
const Dashboard = lazy(() => import('./components/Dashboard/CurbVisualizerApp'));

ReactDOM.render(
  <Suspense fallback={null}>
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/dashboard/" component={Dashboard} />
      </Switch>
    </Router>
  </Suspense>,
  document.getElementById('wrapper'),
);
